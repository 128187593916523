<template>
  <MainLayout fluid>
      <MerchantsTable />
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemTableTemplate from '@/components/ItemTableTemplate'
import ItemTable from '@/components/ItemTable'
import Merchant from '@/resources/Merchant'

const MerchantsTable = {
  ...ItemTableTemplate,
  mixins: [ItemTable(Merchant)]
}

export default {
  components: { MainLayout, MerchantsTable },
  metaInfo() {
    return {
      title: this.$t('merchants.title'),
    }
  },
};
</script>